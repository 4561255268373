<template>
  <div class="container">
    <el-tabs value="1" @tab-click="tabClick">
      <el-tab-pane label="已下单" name="1"></el-tab-pane>
      <el-tab-pane label="已派单" name="2"></el-tab-pane>
      <el-tab-pane label="已处理" name="3"></el-tab-pane>
      <el-tab-pane label="已完结" name="4"></el-tab-pane>
    </el-tabs>
    <div class="handle-box" style="float: left;">
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      <el-button type="danger" icon="el-icon-delete" @click="delAll"
        >批量删除</el-button
      >
      <el-button
        v-loading="exportLoading"
        type="primary"
        icon="el-icon-upload2"
        @click="exportToExcel"
        >导出</el-button
      >
    </div>
    <div style="float: right;">
      <el-form
        ref="queryForm"
        :model="query"
        :inline="true"
        label-width="120px"
      >
        <el-form-item prop="depId">
          <el-cascader
            v-model="query.depIds"
            :options="departmentList"
            clearable
            :show-all-levels="false"
            :props="{
              value: 'id',
              label: 'name',
              children: 'children',
              multiple: true,
              emitPath: false
            }"
            placeholder="请选择所属部门"
          ></el-cascader>
        </el-form-item>
        <el-form-item prop="name">
          <el-input
            v-model="query.name"
            placeholder="请输入报修人名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="datearray">
          <el-date-picker
            v-model="query.datearray"
            type="daterange"
            :picker-options="queryPickerOptions"
            range-separator="至"
            start-placeholder="报修开始日期"
            end-placeholder="报修结束日期"
            align="right"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="type">
          <el-select
            v-model="query.type"
            placeholder="请选择报修类型"
            clearable
          >
            <el-option
              v-for="item in typeList"
              :key="item.code"
              :label="item.text"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search"
            >搜索</el-button
          >
          <el-button icon="el-icon-reset" @click="resetForm('queryForm')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div style="clear: both;"></div>

    <!--数据表格-->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-row>
            <el-col :span="4">
              <el-tag style="float: left;">满意度</el-tag>
              <el-rate
                v-model="props.row.commentStatus"
                style="float: left;margin-top: 10px;"
                disabled
                :colors="['#99A9BF', '#F7BA2A', '#FF9900']"
                :texts="['非常差', '差', '一般', '好', '非常好']"
                show-text
              ></el-rate>
            </el-col>
            <el-col :span="12">
              <el-tag>评论内容</el-tag>
              {{ props.row.commentContent }}
            </el-col>
            <el-col :span="8">
              <el-tag>报修原因</el-tag>
              {{ props.row.reason }}
            </el-col>
          </el-row>
          <el-divider content-position="left">上报图片</el-divider>
          <template v-if="props.row.imageList.length > 0">
            <el-row :gutter="20">
              <el-col
                v-for="item in props.row.imageList"
                :key="item.id"
                :span="8"
              >
                <el-image :src="item.url"></el-image>
              </el-col>
            </el-row>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="报修人名称" prop="name" />
      <el-table-column label="手机号码" prop="telephone" />
      <el-table-column label="报修部门" prop="department" />
      <el-table-column label="报修日期" prop="repairDate" sortable />
      <el-table-column label="完结日期" prop="finishDate" sortable />
      <el-table-column label="报修地址" prop="address" />
      <el-table-column label="楼层" prop="floor" />
      <el-table-column label="房号" prop="roomno" />
      <el-table-column label="报修类型" prop="type" width="100" align="center">
        <template slot-scope="scope">
          <el-tag>{{ scope.row.type }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="报修原因" prop="reason" show-overflow-tooltip />
      <el-table-column label="操作" width="260" align="center">
        <template slot-scope="scope">
          <!-- <el-button
            v-if="scope.row.status !== 1"
            type="primary"
            size="small"
            @click="exportMaintenance(scope.row.id)"
            >导出维修单</el-button
          > -->
          <el-button
            v-if="scope.row.status == 1"
            type="success"
            size="small"
            @click="showDispatch(scope.row)"
            >派单</el-button
          >
          <el-button
            v-if="scope.row.status == 2"
            size="small"
            @click="cancelBack(scope.row)"
            >撤回</el-button
          >
          <el-button type="danger" size="small" @click="onDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!--任务派单 -->
    <el-dialog title="任务派单" width="30%" :visible.sync="selectDlgShow">
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="ruleForm" :model="form" label-width="120px">
          <el-form-item label="派单人员" required>
            <el-select
              v-model="form.userid"
              filterable
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in adminUserList"
                :key="item.userid"
                :label="item.name"
                :value="item.userid"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 12px">
                  {{ item.department }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectDlgShow = false">关 闭</el-button>
        <el-button type="primary" @click="doDispatch('ruleForm')"
          >确定派单</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { download } from '@/utils/request';
import {
  getRepairList,
  delRepair,
  addDispatch,
  deleteDispatch
} from '@/api/device';
import { getDepartmentList } from '@/api/admin/department';
import { getListByRoleAndDepart } from '@/api/admin/user';
import Dict from '@/components/common/dict';

export default {
  name: 'DeviceRepair',
  data() {
    return {
      tabPanel: 'first',
      queryPickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      typeList: Dict.repair_type,
      form: {
        id: '',
        userid: ''
      },
      query: {
        name: '',
        depId: '',
        depIds: [],
        datearray: '',
        type: '',
        status: 1
      },
      list: [],
      departmentList: [],
      adminUserList: [],
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        name: '',
        depId: '',
        depIds: [],
        beginDate: '',
        endDate: '',
        type: '',
        status: 1
      },
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      exportLoading: false,
      multipleSelection: []
    };
  },
  created() {
    this.fetchData();
    this.loadDepartmentList();
  },
  methods: {
    async exportMaintenance(id) {
      await download({
        url: `${process.env.VUE_APP_HOST}/device/dispatch/exportRepairOrder?id=${id}`
      });
    },
    //加载部门列表
    loadDepartmentList() {
      this.departmentList = [];
      getDepartmentList().then(response => {
        this.departmentList = response.data;
      });
    },
    tabClick(tab, event) {
      let status = tab.name;
      this.pageInfo.status = status;
      this.query.status = status;
      this.onRefresh();
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      if (this.query.datearray != null) {
        let beginDate = this.query.datearray[0];
        let endDate = this.query.datearray[1];
        this.pageInfo.beginDate = beginDate;
        this.pageInfo.endDate = endDate;
      } else {
        this.pageInfo.beginDate = '';
        this.pageInfo.endDate = '';
      }
      this.pageInfo.name = this.query.name;
      this.pageInfo.status = this.query.status;
      this.pageInfo.depId = this.query.depId;
      this.pageInfo.depIds = this.query.depIds;
      this.pageInfo.type = this.query.type;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
      this.loadDepartmentList();
    },
    //批量删除
    delAll() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请至少选择一条数据');
        return;
      }
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delRepair({ ids: ids })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //删除
    onDel(data) {
      this.$confirm('您确定要删除此条数据吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delRepair({ id: data.id })
          .then(resp => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //查询列表
    fetchData() {
      this.listLoading = true;
      getRepairList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
        this.formatterData(this.list);
      });
    },
    formatterData(datalist) {
      for (var i = 0; i < datalist.length; i++) {
        datalist[i].type = Dict.getText(datalist[i].type, Dict.repair_type);
      }
    },
    //状态修改
    onStatus(row, status) {
      // updateStatus({ id: row.id, status: status }).then(resp => {
      //   if (resp.code == 200) {
      //     this.$message({
      //       message: '操作成功',
      //       type: 'success'
      //     });
      //     this.onRefresh();
      //   } else {
      //     this.$message.error('操作失败');
      //   }
      // });
    },
    //任务派单
    showDispatch(row) {
      this.selectDlgShow = true;
      this.form = {
        id: '',
        userid: ''
      };
      getListByRoleAndDepart({ roleCode: 'repair' }).then(resp => {
        this.adminUserList = resp.data;
      });
      this.form.id = row.id;
    },
    //派单
    doDispatch(formName) {
      let userid = this.form.userid;
      if (userid == '' || userid == null) {
        this.$message({
          message: '请先选择派单人员',
          type: 'warning'
        });
        return;
      }
      addDispatch(this.form).then(resp => {
        if (resp.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.onRefresh();
        } else {
          this.$message.error('操作失败');
        }
        this.selectDlgShow = false;
      });
    },
    //派单撤回
    cancelBack(row) {
      deleteDispatch({ id: row.id }).then(resp => {
        if (resp.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.onRefresh();
        } else {
          this.$message.error('操作失败');
        }
        this.selectDlgShow = false;
      });
    },
    //excel数据导出
    exportToExcel() {
      this.exportLoading = true;
      require.ensure([], () => {
        const {
          export_json_to_excel
        } = require('../../../assets/js/Export2Excel.js');
        const tHeader = [
          '报修人名称',
          '手机号码',
          '报修部门',
          '报修日期',
          '完结日期',
          '报修具体地址',
          '楼层',
          '房号',
          '报修类型',
          '报修原因',
          '完结状态'
        ];
        const filterVal = [
          'name',
          'telephone',
          'department',
          'repairDate',
          'finishDate',
          'address',
          'floor',
          'roomno',
          'type',
          'reason',
          'status'
        ];
        const list = this.list;
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, '设备报修记录');
        this.exportLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.el-dropdown {
  margin-left: 10px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}
.el-tag {
  margin-top: 10px;
  margin-right: 10px;
}
</style>
